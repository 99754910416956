<template>
  <div class="c-bitts-dropdown">
    <popover
      v-bind="{ transitionName: transition }"
      :align="align"
      :placement="placement"
      trigger="click"
      :open="!disabled && isVisible"
      :destroy-tooltip-on-hide="true"
      :get-popup-container="getPopupContainer"
      :overlay-class-name="`c-bitts-dropdown-content ${parentClass}`"
      @open-change="onVisibleChange"
    >
      <span :class="isVisible && 'active'" class="c-bitts-dropdown__prompt">
        <button
          v-if="props.prompt"
          class="c-bitts-dropdown__prompt--basic"
          :class="{
            'bg-opacity-0': props.transparent,
            'opacity-50': props.transparent && disabled,
            'bg-opacity-100 bg-white border': !props.transparent,
            'bg-neutral-background-disabled': !props.transparent && disabled,
            'cursor-not-allowed': props.disabled,
          }"
        >
          <span>{{ props.prompt }}</span>
          <FontAwesomeIcon
            :icon="['fas', 'angle-down']"
            :style="{ height: '12px', width: '12px' }"
            class="text-neutral-text"
          />
        </button>
        <BittsButton
          v-else-if="!$slots.prompt"
          variant="ghost"
          size="x-small"
          :center-icon="['fas', 'ellipsis-h']"
          class="text-info-accent"
        />
      </span>
      <slot name="prompt" />
      <template #content>
        <div class="c-bitts-dropdown-selections">
          <slot name="content">
            <component
              :is="option.tooltip && option.value ? BittsTooltip : 'div'"
              v-for="(option, i) in options"
              :key="`${option.value || option}_${i}`"
              placement="right"
              :class="{
                'c-bitts-dropdown-selections__option': option.value,
                [option.classes || '']: true,
              }"
              :include-cta="option.tooltip && option.value && option.includeCta"
              :button-text="
                (option.tooltip && option.value && option.buttonText) ??
                option.buttonText
              "
              @cta-clicked="$emit('cta-clicked')"
            >
              <div>
                <component
                  :is="option.value ? 'button' : 'div'"
                  :tabindex="option.value ? '0' : '-1'"
                  class="text-left w-full"
                  :class="{
                    'c-bitts-dropdown-selections__header': !option.value,
                    'font-bold': !option.value && headerBold,
                    'text-info-text': option.active,
                    'text-danger-text': option.danger,
                    disabled: option.disabled,
                    'cursor-pointer': !options.disabled,
                  }"
                  @click="handleSelection(option)"
                >
                  <div
                    v-if="!option.value && i > 0"
                    class="c-bitts-dropdown-selections__section-separator"
                    :class="{ 'pb-12': option }"
                  />
                  <div class="flex items-center justify-start px-8 gap-8">
                    <FontAwesomeIcon
                      v-if="option.icon || option.iconType"
                      :icon="option.iconType || ['far', 'plus-circle']"
                      :style="{ height: '16px', width: '16px' }"
                      :class="optionIconColor(option)"
                    />
                    <BittsSvg
                      v-if="option.svg"
                      class="h-16 w-16"
                      :svg="option.svg"
                    />
                    <span>{{ option.value ? option.display : option }}</span>
                  </div>
                </component>
              </div>
              <template #title>
                {{ option.tooltip }}
              </template>
            </component>
          </slot>
        </div>
      </template>
    </popover>
  </div>
</template>
<script setup>
import { Popover } from 'ant-design-vue';
import { ref } from 'vue';

import BittsButton from '../BittsButton/BittsButton.vue';
import BittsSvg from '../BittsSvg/BittsSvg.vue';
import BittsTooltip from '../BittsTooltip/BittsTooltip.vue';

const emit = defineEmits(['selection', 'visibility-changed', 'cta-clicked']);
const props = defineProps({
  prompt: {
    /* If no prompt, use the FontAwesomeIcon dots */
    type: String,
    default: null,
    required: false,
  },
  align: {
    type: Object,
    default: undefined,
  },
  options: {
    type: Array,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  transparent: {
    type: Boolean,
    default: false,
  },
  transition: {
    type: String,
    default: 'fade',
  },
  mountToBody: {
    type: Boolean,
    default: true,
  },
  parentClass: {
    type: String,
    default: '',
  },
  placement: {
    type: String,
    default: 'bottomRight',
    validator: (val) =>
      [
        'top',
        'right',
        'bottom',
        'left',
        'topLeft',
        'topRight',
        'bottomLeft',
        'bottomRight',
        'rightBottom',
        'leftBottom',
        'rightTop',
        'leftTop',
      ].includes(val),
  },
  headerBold: {
    type: Boolean,
    default: false,
  },
  isInitiallyVisible: {
    type: Boolean,
    default: false,
  },
});

const isVisible = ref(props.isInitiallyVisible);

function getPopupContainer(trigger) {
  return props.mountToBody ? document.body : trigger.parentElement;
}

function onVisibleChange() {
  isVisible.value = !isVisible.value;
  emit('visibility-changed', isVisible.value);
}

const handleSelection = (option) => {
  if (!option.value) return; /* Don't select titles */
  isVisible.value = false;
  emit('selection', option);
  emit('visibility-changed', isVisible.value);
};

function optionIconColor(option) {
  if (option.iconColor) return option.iconColor;
  if (option.danger) return 'text-danger-accent';
  return 'text-neutral-accent';
}
</script>
<style lang="pcss">
/* The dropdown prompt */
.c-bitts-dropdown {
  @apply inline-flex;
  .ant-popover-arrow {
    @apply invisible;
  }
  .c-bitts-dropdown__prompt {
    @apply cursor-pointer whitespace-nowrap;
  }

  .c-bitts-dropdown__prompt--basic {
    @apply flex items-center gap-8 border-neutral-border px-12 py-4 rounded-8 text-neutral-text-strong;
  }

  .c-bitts-dropdown__prompt--basic,
  .c-bitts-dropdown__prompt--dots {
    &:focus-visible {
      outline-color: theme('colors.neutral.border-focus');
      outline-style: solid;
      outline-width: 2px;
    }
  }
}

/* The actual dropdown content */
.c-bitts-dropdown-content {
  @apply pt-0 absolute;

  &.ant-popover {
    font-family: inherit;
  }

  .ant-popover-arrow {
    @apply invisible pt-0;
  }

  .c-bitts-dropdown-selections__header {
    @apply text-neutral-text text-sm text-left pb-4 cursor-default;
  }

  .ant-popover-inner-content {
    @apply p-0;
  }

  .ant-popover-inner {
    @apply border border-neutral-border rounded-8 p-0;
    box-shadow: 0px 4px 8px theme('colors.neutral[300]') !important;
  }

  &.ant-popover-placement-bottomRight:not(.ant-popover-arrow-hidden) {
    @apply pt-0;
  }

  .c-bitts-dropdown-selections {
    @apply flex flex-col py-4 rounded-8 bg-white;

    .c-bitts-dropdown-selections__section-separator {
      @apply border-t border-neutral-border mt-4;
    }

    .c-bitts-dropdown-selections__option {
      @apply text-center text-neutral-text-strong py-4 mx-4 rounded-4;

      &:hover,
      &:focus-visible {
        @apply bg-neutral-background-weak;
        outline: none;
      }
    }
  }
}
</style>
